import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useRef } from 'react'
import BenefitsClube from 'src/components/ClubComponents/BenefitsClube'
import FaqClubeDecathlon from 'src/components/ClubComponents/FaqClubeDecathlon'
import MainClubeDecathlon from 'src/components/ClubComponents/MainClubeDecathlon'

const ClubeDecathlonInstitutional = () => {
  const faqRef = useRef<null | HTMLDivElement>(null)

  return (
    <>
      <GatsbySeo title="Clube | Decathlon" noindex nofollow />
      <MainClubeDecathlon />
      <BenefitsClube reference={faqRef} />
      <FaqClubeDecathlon reference={faqRef} />
    </>
  )
}

export default ClubeDecathlonInstitutional
